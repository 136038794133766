import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useState } from "react";

import styles from "./MobileHeaderButton.module.scss";

const MobileHeaderButton = ({ className, onClick, title }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <button
      aria-expanded={isOpen}
      className={classNames(styles.button, className)}
      name="GlobalHeader:MobileHeaderButton"
      onClick={() => {
        if (onClick) {
          onClick();
        }
        setIsOpen(!isOpen);
      }}
      title={title}
      type="button"
    >
      <svg
        height="20px"
        version="1.1"
        viewBox="0 0 20 20"
        width="20px"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <line strokeWidth="1" x1="0" x2="20" y1="6.6" y2="6.6" />
          <line strokeWidth="1" x1="0" x2="20" y1="13.3" y2="13.3" />
        </g>
        <g>
          <line strokeWidth="1" x1="0" x2="20" y1="10" y2="10" />
          <line strokeWidth="1" x1="0" x2="20" y1="10" y2="10" />
        </g>
      </svg>
    </button>
  );
};

MobileHeaderButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  title: PropTypes.string,
};

export default MobileHeaderButton;
